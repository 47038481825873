body {
	margin: 0;
	overflow-x: hidden;
}

.fullscreen-button-container {
	opacity: 0;

	&:hover {
		opacity: 0.6;
	}
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 100 900;
	font-display: swap;
	src: url("../public/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype"); /* TTF format */
	font-variation-settings:
		"wght" 400,
		"slnt" 0; /* Default weight: 400, default slant: 0 */
}

@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-Thin.ttf") format("truetype"); /* TTF format */
}

@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLight.ttf") format("truetype"); /* TTF format */
}

@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf") format("truetype"); /* TTF format */
}

@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf") format("truetype"); /* TTF format */
}

@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf") format("truetype"); /* TTF format */
}

@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf") format("truetype"); /* TTF format */
}

@font-face {
	font-family: "IBM Plex Sans";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf") format("truetype"); /* TTF format */
}
